import { Pipe, PipeTransform } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';

@Pipe({
  name: 'routeActiveDetect',
})
export class RouteActiveDetectPipe implements PipeTransform {

  defaultRouteSettings: IsActiveMatchOptions = { matrixParams: 'ignored', queryParams: 'ignored', paths: 'subset', fragment: 'ignored'};

  constructor(
    private router: Router
  ) {
    
  }

  transform(url_sets: any[], _last_checked_at: number = null, route_settings: IsActiveMatchOptions = this.defaultRouteSettings): boolean {
    let _is_any_url_active = false;
    for (let _single_url of url_sets) {
      _is_any_url_active = this.router.isActive(_single_url, route_settings);
      if (_is_any_url_active) {
        break;
      }
    }
    return _is_any_url_active;
  }

}
