import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSelectionDialogComponent } from './account-selection-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ProgressSpinnerModule } from '@studentapp_shared/utils/progress-spinner/progress-spinner.module';
import { NoDataViewUiModule } from '@studentapp_shared/utils/no-data-view-ui/no-data-view-ui.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AccountSelectionDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    ProgressSpinnerModule,
    NoDataViewUiModule,
  ],
  exports: [
    AccountSelectionDialogComponent
  ]
})
export class AccountSelectionDialogModule { }
