import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoerschoolBranchSelectionDialogComponent } from './doerschool-branch-selection-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    DoerschoolBranchSelectionDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
  ],
  exports: [
    DoerschoolBranchSelectionDialogComponent
  ]
})
export class DoerschoolBranchSelectionDialogModule { }
