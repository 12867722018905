import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-no-data-view-ui',
  templateUrl: './no-data-view-ui.component.html',
  styleUrls: ['./no-data-view-ui.component.scss']
})
export class NoDataViewUiComponent implements OnInit {
  
  @Input() NoDataText = 'No Data Found';
  @Input() useTextFormatAs: 'NORMAL' | 'HTML' = 'NORMAL';
  @Input() panelClass = '';
  @Input() boxType = 'INFO';
  @Input() visibleIcon = true;
  @Input() iconPath = 'assets/images/svg/info.svg';
  @Input() actionAllowed = false;
  @Input() actionText = '';
  @Input() actionBtnClass = 'btn-xs btn-purple';

  @Output() doTriggerAction = new EventEmitter();
  
  constructor() { }

  triggerActionReq() {
    this.doTriggerAction.emit('action');
  }

  ngOnInit(): void {
  }

}
