import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() { }

  /* set selected student's data */
  setCurrentUserData(CurrentUser) {
    localStorage.setItem('CurrentUser', JSON.stringify(CurrentUser));
  }

  /* get currently logged in user's all information */
  getCurrentUser() {
    if (localStorage.getItem('CurrentUser'))
      return JSON.parse(localStorage.getItem('CurrentUser'));
    else
      return null;
  }

  /* get currently logged in user's information */
  getCurrentUserData() {
    if (localStorage.getItem('CurrentUser'))
      return JSON.parse(localStorage.getItem('CurrentUser')).user;
    else
      return null;
  }

  getUserIdLocalStorage() {
    const userData = this.getCurrentUser();
    if (userData && userData.user) {
      return userData.user.id;
    }
    return 0;
  }

  /* remove current user's data */
  removeCurrentUserData() {
    localStorage.removeItem('CurrentUser');
  }

  /* set selected student's data */
  setCurrentStudentData(CurrentStudent) {
    localStorage.setItem('CurrentStudent', JSON.stringify(CurrentStudent));
  }

  /* get currently logged in student information */
  getCurrentStudentData() {
    if (localStorage.getItem('CurrentStudent')) {
      return JSON.parse(localStorage.getItem('CurrentStudent'));
    }
    return null;
  }

  getCurrentStudentId() {
    if (localStorage.getItem('CurrentStudent')) {
      const _student_info = JSON.parse(localStorage.getItem('CurrentStudent'));
      return _student_info.id;
    }
    return 0;
  }

  getCurrentDoerCampusBranchInfo() {
    if (localStorage.getItem('CurrentDoerCampusBranch')) {
      return JSON.parse(localStorage.getItem('CurrentDoerCampusBranch'));
    }
    return null;
  }

  setCurrentDoerCampusBranchInfo(CurrentBranch) {
    localStorage.setItem('CurrentDoerCampusBranch', JSON.stringify(CurrentBranch));
  }

  removeCurrentDoerCampusBranchInfo() {
    localStorage.removeItem('CurrentDoerCampusBranch');
  }

  enableAutoLoginBySessionStorage() {
    sessionStorage.setItem('autoLogin', "true");
  }

  getAutoLoginInStateFromSessionStorage() {
    return sessionStorage.getItem('autoLogin');
  }

  /**
   * Store user input given at the time of registration, 
   * Currently purpose of storing this value to offer user do automatically login after some mandatory steps
   */
  storeUserInputAtRegistration(_user_registration_info: any) {
    sessionStorage.setItem('registration_user_input_info',JSON.stringify(_user_registration_info));
  }

  /**
   * get user input given at the time of registration
   */
  getUserInputAtRegistration() {
    if (sessionStorage.getItem('registration_user_input_info')) {
      return JSON.parse(sessionStorage.getItem('registration_user_input_info'));
    }
    return null;
  }

  /**
   * remove user input given at the time of registration
   */
  removeUserInputAtRegistration() {
    sessionStorage.removeItem('registration_user_input_info');
  }

  /**
   * Store temporary account info before login
   * Generally this value we get after user register their account or trying to login and login blocked due to some reason like verification
   * Currently purpose of storing this value to offer user phone number verification or email verification
   */
  storeTemporaryAccountInfoBeforeLogin(_user_account_info: any) {
    sessionStorage.setItem('account_info_before_login',JSON.stringify(_user_account_info));
  }

  /**
   * get temporary account info before login
   */
  getTempoararyAccountInfoBeforeLogin() {
    if (sessionStorage.getItem('account_info_before_login')) {
      return JSON.parse(sessionStorage.getItem('account_info_before_login'));
    }
    return null;
  }

  /**
   * remove temporary account info before login
   */
  removeTemporaryAccountInfoBeforeLogin() {
    sessionStorage.removeItem('account_info_before_login');
  }

  storeTemporaryStudentInfoAfterStudentRegistration(_user_info: any) {
    sessionStorage.setItem('temporary_student_info',JSON.stringify(_user_info));
  }

  removeTemporaryStudentInfoAfterStudentRegistration() {
    sessionStorage.removeItem('temporary_student_info');
  }

  getCurrentStudentDataWithFallback() {
    if (localStorage.getItem('CurrentStudent')) {
      return JSON.parse(localStorage.getItem('CurrentStudent'));
    }
    else if (sessionStorage.getItem('temporary_student_info')) {
      // PREVIOUSLY USED AS LOCAL STORAGE studentInfo to give product selection at time of registration
      return JSON.parse(sessionStorage.getItem('temporary_student_info'));
    }
    return null;
  }

  getCurrentStudentIdWithFallback() {
    if (localStorage.getItem('CurrentStudent')) {
      const _student_info = JSON.parse(localStorage.getItem('CurrentStudent'));
      return _student_info.id;
    }
    else if (sessionStorage.getItem('temporary_student_info')) {
      const _student_info = JSON.parse(sessionStorage.getItem('temporary_student_info'));
      return _student_info.id;
    }
    return 0;
  }

  /**
   * Store temporary account/user info before doing some account specific operation
   * Generally this value we store regarding user details, like account_id, name etc
   * Currently purpose of storing this value
   *  to offer main account to act as linked account also, like reset password, subscription
   *  Also used when product selection used after student selection
   */
  storeTemporaryUserInfoAfterRegistration(_user_info: any) {
    sessionStorage.setItem('temporary_user_info',JSON.stringify(_user_info));
  }

  getTemporaryUserInfoAfterRegistration() {
    if (sessionStorage.getItem('temporary_user_info')) {
      // PREVIOUSLY USED AS LOCAL STORAGE accountInfo to give product selection at time of registration
      return JSON.parse(sessionStorage.getItem('temporary_user_info'));
    }
    return null;
  }

  removeTemporaryUserInfoAfterRegistration() {
    sessionStorage.removeItem('temporary_user_info');
  }

  getCurrentUserDataWithFallback() {
    if (localStorage.getItem('CurrentUser')) {
      return JSON.parse(localStorage.getItem('CurrentUser')).user;
    }
    else if (sessionStorage.getItem('temporary_user_info')) {
      return JSON.parse(sessionStorage.getItem('temporary_user_info'));
    }
    return null;
  }

  getCurrentAccountIdWithFallback() {
    if (localStorage.getItem('CurrentUser')) {
      const _account_info = JSON.parse(localStorage.getItem('CurrentUser')).user;
      if (_account_info) {
        return _account_info.id;
      }
    }
    else if (sessionStorage.getItem('temporary_user_info')) {
      const _account_info = JSON.parse(sessionStorage.getItem('temporary_user_info'));
      return _account_info.id;
    }
    return 0;
  }

  getCurrentAccountIdWithLinkedAccountConsideration() {
    const _temp_account_info = this.getTemporaryUserInfoAfterRegistration();
    if (_temp_account_info) {
      return _temp_account_info.id;
    }
    else {
      const _account_info = this.getCurrentUserData();
      if (_account_info) {
        return _account_info.id;
      }
    }
    return null;
  }

  storeDirectLoggedInformationAsSwitchFeature(_login_full_info: any) {
    sessionStorage.setItem('direct_dd_login_data',JSON.stringify(_login_full_info));
  }

  removeDirectLoggedInformationAsSwitchFeature() {
    sessionStorage.removeItem('direct_dd_login_data');
  }

  getDirectLoggedInformationAsSwitchFeature() {
    if (sessionStorage.getItem('direct_dd_login_data')) {
      return JSON.parse(sessionStorage.getItem('direct_dd_login_data'));
    }
    return null;
  }

  enableDirectLoginBySessionStorage() {
    sessionStorage.setItem('directLogin', "true");
  }

  getDirectLoginInStateFromSessionStorage() {
    return sessionStorage.getItem('directLogin');
  }

  removeDirectLoginInStateFromSessionStorage() {
    return sessionStorage.removeItem('directLogin');
  }
}
