<div class="warning-modal-c">
    <span class="ico" [ngClass]="dialogIconClass">
        <img [src]="dialogIcon" alt="">
    </span>
    <p>{{ msg }}</p>
    <div class="actions">
        <button class="btn btn-sm btn-success" *ngIf="isConfirmBtnVisible" (click)="doAction()">{{ btnText }}</button>
        <button class="btn btn-sm btn-warning" mat-dialog-close>Cancel</button>
    </div>
</div>
