import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlModule } from '@studentapp_core/pipes/safe-html/safe-html.module';
import { NoDataViewUiComponent } from './no-data-view-ui.component';

@NgModule({
  declarations: [
    NoDataViewUiComponent
  ],
  imports: [
    CommonModule,
    SafeHtmlModule,
  ],
  exports: [
    NoDataViewUiComponent
  ]
})
export class NoDataViewUiModule { }
