import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }


  /**
   * @description {{this will store the all lecture contents or components (video / quiz / test / text-content) for view}}
  */
  public LectureContent = new BehaviorSubject<any>({
    content_info: [],
    lect_info: []
  });
  LectureContent$ = this.LectureContent.asObservable();

  public isStudentSelectionDialogOpen: boolean = false;

  /* set the one all lecture contents or components */
  setLectureContent(LectureContent: any) {
    console.log('all lecture components are pushed ', LectureContent);
    this.LectureContent.next(LectureContent);
  }




  /**
   * @description {{this will store the selected lecture content data (video / quiz / test / text-content) for view}}
  */
  public LectureContentData = new BehaviorSubject<any>(null);
  LectureContentData$ = this.LectureContentData.asObservable();



  /* set the one lecture content data at a time */
  setLectureContentData(lectureContentData: any) {
    console.log('one lecture component data pushed ', lectureContentData);
    this.LectureContentData.next(lectureContentData);
  }

  /* get the one lecture content data at a time */
  getLectureContentData() {
    if (this.LectureContentData$) {
      return this.LectureContentData$;
    } else {
      return null;
    }
  }



  /**
   * @description {{ wathcer for currentUser set in local-storage && currentStudent set in session-storage }}
  */
  public StorageUpdate = new BehaviorSubject<Boolean>(false);
  StorageUpdate$ = this.StorageUpdate.asObservable();


  update_storage(is_storageUpdate: Boolean) {
    console.log('storage update need ', is_storageUpdate);
    this.StorageUpdate.next(is_storageUpdate);
  }


  /**
   * @description {{ wathcer for currentUser set in local-storage && currentStudent set in session-storage }}
  */
  public CourseList = new BehaviorSubject<any>(
    {
      maintopic_info: [],
      next_main_topic: null
    }
  );
  CourseList$ = this.CourseList.asObservable();


  setContentList(CourseList: any) {
    console.log('Course list ', CourseList);
    this.CourseList.next(CourseList);
  }

  studentSelection = new BehaviorSubject<any>(null);
  studentSelction$ = this.studentSelection.asObservable();
  updateStudentSelection(data: any) {
    this.studentSelection.next(data)
  }

  manageStudentSelection(selectedValues: any[], newValue: any) {
    const index = selectedValues.findIndex((eachSelection) => eachSelection.type === newValue.type);
    if (index >= 0) {
      if (newValue.id) {
        selectedValues[index] = newValue;
      }
      else {
        selectedValues.splice(index, 1);
      }
    }
    else if (newValue.id) {
      selectedValues.push(newValue);
    }
    return selectedValues;
  }

  addStudentSelection(selectedValues: any[], newValue: any) {
    const index = selectedValues.findIndex((eachSelection) => eachSelection.type === newValue.type && eachSelection.id == newValue.id);
    if (index === -1) {
      selectedValues.push(newValue);
    }
    return selectedValues;
  }

  removeStudentSelection(selectedValues: any[], newValue: any) {
    return selectedValues.filter((eachSelection) => eachSelection.type !== newValue.type);
  }

  updateStudentSelectionGrades(selected_grade_details: any, selected_subject_detail: any, subjects_list: any[] = []) {
    let selectedValue = JSON.parse(JSON.stringify(this.studentSelection.value));
    if (!selectedValue) {
      selectedValue = [];
    }
    selectedValue = this.removeStudentSelection(selectedValue, { type: 'GRADE' });
    selectedValue = this.removeStudentSelection(selectedValue, { type: 'SUBJECT' });
    if (selected_grade_details) {
      selectedValue = this.addStudentSelection(selectedValue, { id: selected_grade_details.id, type: 'GRADE', name: selected_grade_details.grade_name });
    }
    if (selected_subject_detail) {
      selectedValue = this.addStudentSelection(selectedValue, { id: selected_subject_detail.id, type: 'SUBJECT', name: selected_subject_detail.name });
    }
    else {
      if (subjects_list.length) {
        subjects_list.forEach((eachSubject) => {
          selectedValue = this.addStudentSelection(selectedValue, { id: eachSubject.id, type: 'SUBJECT', name: eachSubject.name });
        })
      }
    }
    this.updateStudentSelection(selectedValue);
  }

  learnPageTitle = new BehaviorSubject<any>('');
  learnPageTitle$ = this.learnPageTitle.asObservable();
  updateLearnPageTitle(data: string) {
    this.learnPageTitle.next(data)
  }

  currentSubjectListByGrade = new BehaviorSubject<any>([]);
  currentSubjectListByGrade$ = this.currentSubjectListByGrade.asObservable();
  updateCurrentSubjectList(data: any[]) {
    this.currentSubjectListByGrade.next(data)
  }

  learnContentSelectedByStudent = new BehaviorSubject<any>(null);
  learnContentSelectedByStudent$ = this.learnContentSelectedByStudent.asObservable();
  updateLearnContentSelection(data: any) {
    this.learnContentSelectedByStudent.next(data)
  }

  dynamicPageConfiguration = new BehaviorSubject<any>(null);
  dynamicPageConfiguration$ = this.dynamicPageConfiguration.asObservable();
  updateDynamicPageConfig(data: any) {
    this.dynamicPageConfiguration.next(data)
  }

  isOnlineClassRunning = false;
  
  selectedClassDetails = new BehaviorSubject<any>(null);
  selectedClassDetails$ = this.selectedClassDetails.asObservable();
  updateClassDetails(classDetails: any) {
    this.selectedClassDetails.next(classDetails);
  }

  forceEndClassIdentifier = new BehaviorSubject<any>(null);
  forceEndClassIdentifier$ = this.forceEndClassIdentifier.asObservable();
  forceEndClassByRouteChange(endStatus: any) {
    this.forceEndClassIdentifier.next(endStatus);
  }

  anotherDeviceConnctedClassData = new BehaviorSubject<boolean>(false);
  anotherDeviceConnctedClassData$ = this.anotherDeviceConnctedClassData.asObservable();
  updateAnotherDeviceConnctedSameClassInfo(deviceInfo: boolean) {
    this.anotherDeviceConnctedClassData.next(deviceInfo);
  }


  globalLiveClassInfo = new BehaviorSubject<any>([]);
  globalLiveClassInfo$ = this.globalLiveClassInfo.asObservable();
  updateGlobalLiveCLassInfo(classInfo: any) {
    this.globalLiveClassInfo.next(classInfo);
  }

  infiniteScrollHandling(targetELem: any, bottomScrollConfig: any) {
    if (targetELem.scrollTop <= bottomScrollConfig.scrollOffset) {
      if ((targetELem.scrollHeight - targetELem.scrollTop) > (bottomScrollConfig.lastTopPosition + bottomScrollConfig.scrollOffset)) {
        return { topReached: true, top: targetELem.scrollTop, scrolledFromBottom: targetELem.scrollHeight - targetELem.scrollTop };
      }
    }
    else {
      if ((targetELem.scrollHeight - (targetELem.offsetHeight + bottomScrollConfig.scrollOffset)) <= targetELem.scrollTop) {
        if (targetELem.scrollTop > (bottomScrollConfig.lastPosition + bottomScrollConfig.scrollOffset)) {
          return { bottomReached: true, bottom: targetELem.scrollTop }
        }
      }
      return null;
    }
  }

  // infiniteScrollHandling2(targetELem: any, scrollConfig: any) {
  //   const scrollDirection = targetELem.scrollTop > scrollConfig.lastScrollTop ? 'down' : 'up';
  //   const lastScrollTop = targetELem.scrollTop;
  //   let lastLoadPositionUp = 0;
  //   let lastLoadPositionDown = 0;

  //   if (scrollDirection === 'up' && (lastScrollTop - scrollConfig.lastLoadPositionUp) < scrollConfig.scrollOffset) {
  //     // Call your API to load more data, then update lastLoadPositionUp
  //     lastLoadPositionUp = lastScrollTop;
  //     return { topReached: true, lastScrollTop: lastScrollTop, lastLoadPositionUp: lastLoadPositionUp };
  //   }

  //   if (scrollDirection === 'down' && (targetELem.scrollHeight - lastScrollTop - scrollConfig.lastLoadPositionDown) < scrollConfig.scrollOffset) {
  //     // Call your API to load more data, then update lastLoadPositionDown
  //     lastLoadPositionDown = targetELem.scrollHeight - lastScrollTop;
  //     return { bottomReached: true, lastScrollTop: lastScrollTop, lastLoadPositionDown: lastLoadPositionDown };
  //   }
  //   return { lastScrollTop: lastScrollTop };
  // }

  infiniteScrollHandling2(targetELem: any, scrollConfig: any, parentElementRef: any = null) {
    const scrollDirection = targetELem.scrollTop > scrollConfig.lastScrollTop ? 'down' : 'up';
    const lastScrollTop = targetELem.scrollTop;
    const lastScrollBottom = targetELem.scrollHeight - lastScrollTop;
    let lastLoadPositionUp = 0;
    let lastLoadPositionDown = 0;

    let scrollEnabled = true;
    if (parentElementRef) {
      const _parent_element_reference = targetELem.closest(parentElementRef);
      if (_parent_element_reference.scrollHeight >= targetELem.scrollHeight) {
        scrollEnabled = false;
      }
      else{
        scrollEnabled = true;
      }
    }

    if (scrollDirection === 'up' && (lastScrollTop - scrollConfig.lastLoadPositionUp) < scrollConfig.scrollOffset) {
      // Call your API to load more data, then update lastLoadPositionUp
      lastLoadPositionUp = lastScrollTop;
      return { topReached: true, lastScrollTop: lastScrollTop, lastLoadPositionUp: lastLoadPositionUp, scrollEnabled };
    }

    if (scrollDirection === 'down' && (targetELem.scrollHeight - lastScrollTop - scrollConfig.lastLoadPositionDown) < scrollConfig.scrollOffset) {
      // Call your API to load more data, then update lastLoadPositionDown
      lastLoadPositionDown = lastScrollBottom;
      return { bottomReached: true, lastScrollTop: lastScrollTop, lastScrollBottom: lastScrollBottom, lastLoadPositionDown: lastLoadPositionDown, scrollEnabled };
    }
    return { lastScrollTop: lastScrollTop, lastScrollBottom: lastScrollBottom, scrollEnabled };
  }

  /**
   * @description {{ wathcer where left-section table-of-content(TOC) need update or not }}
  */
  public LeftSectionTOCupdate = new BehaviorSubject<Boolean>(false);
  LeftSectionTOCupdate$ = this.LeftSectionTOCupdate.asObservable();

  /* if the value is true then API will hit autometically to update left-section table-of-content(TOC) */
  update_leftSectionTOC(is_LeftSectionTOCUpdate: Boolean) {
    console.log('left-section table-of-content(TOC) update need ', is_LeftSectionTOCUpdate);
    this.LeftSectionTOCupdate.next(is_LeftSectionTOCUpdate);
  }




  /**
   * @description {{ check test module started or not }}
  */
  public IsTestStarted = new BehaviorSubject<Boolean>(false);
  IsTestStarted$ = this.LeftSectionTOCupdate.asObservable();

  /* if the value is true then left-panel autometically close and hide back button */
  isTestStarted(isTestStarted: Boolean) {
    this.LeftSectionTOCupdate.next(isTestStarted);
  }



  /**
   * @description {{ store total unread message count }}
  */
  public UnreadMsgCount = new BehaviorSubject<number>(0);
  UnreadMsgCount$ = this.UnreadMsgCount.asObservable();

  /* set number of unread messages */
  setUnreadMsgCount(count: number) {
    this.UnreadMsgCount.next(count);
  }

  /**
   * @description {{ store total unread conversations count }}
  */
  public UnreadConvCount = new BehaviorSubject<number>(0);
  UnreadConvCount$ = this.UnreadConvCount.asObservable();

  /* set number of unread conversations */
  setUnreadConversationCount(count: number) {
    this.UnreadConvCount.next(count);
  }

}
