import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@studentapp_env/environment';

export enum fileTypeMappingEnum {
  'image' = 'Image',
  'video' = 'Video',
  'audio' = 'Audio_files',
  'off_exam' = 'Offline_exam',
  'off_worksheet' = 'Offline_worksheet',
  'ss' = 'Screenshot',
  'white_board_content' = 'White_board_notes',
  'recording' = 'Class_recording',
  'import_files' = 'Import_files',
  'class_asset' = 'Class_assets',
  'chat_attachment_files' = 'Message_attachment',
};

@Injectable({
  providedIn: 'root'
})
export class FileUploadingService {

  env = environment;
  baseUrl = this.env.base_url;
  cdnLinks = this.env.cdn_urls;
  
  constructor(
    private http: HttpClient
  ) { }

  getS3FinalPath(storageType: any) {
    const fileType = fileTypeMappingEnum[storageType];
    if (fileType in this.cdnLinks) {
      return this.cdnLinks[fileType];
    }
    return null;
  }

  doUploadFile(params: any) {
    return this.http.post(`${this.baseUrl}/uploads_handler`, params);
  }

  doUploadFileAsynchronous(params: any) {
    return this.http.post(`${this.baseUrl}/uploads_handler`, params).toPromise();
  }

  doDeleteUploadedFileAsynchronous(params: any) {
    return this.http.post(`${this.baseUrl}/delete_file_from_s3`, params).toPromise();
  }

  compressImageCanvas(file: any, width: number = 300, quality: number = 1): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const imgType = file.type;
        let reader = new FileReader();
        reader.onload = (e: any) => {
          const oCanvas = <HTMLCanvasElement>document.createElement('canvas');
          oCanvas.id = 'custom_canvas';
          oCanvas.style.display = 'none';
          const oCtx = oCanvas.getContext('2d');
          const image = new Image();
          image.onload= () => {
            const iWidth = image.width;
            const iHeight = image.height;
            const oCWidth = oCanvas.width = width;
            const oCHeight = oCanvas.height = Math.floor(iHeight / iWidth * oCWidth);
            oCtx.drawImage(image, 0, 0, oCWidth, oCHeight);
            const newImageUrl = oCanvas.toDataURL(imgType, quality);
            oCanvas.toBlob((blob) => {
              const Newfile: any = new File([blob], file.name, {type: blob.type});
              resolve({ file: Newfile, url: newImageUrl, name: file.name, type: file.type });
            })
          };
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } catch(error) {
        reject(error)
      }
    })
  }
}
