import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-delete-item',
  templateUrl: './warning-delete-item.component.html',
  styleUrls: ['./warning-delete-item.component.scss']
})
export class WarningDeleteItemComponent implements OnInit {

  info: any = null;
  topicInfo = null;
  itemIndex: number;
  msg = '';
  btnText = '';
  dialogIcon = '';
  dialogIconClass = '';
  isConfirmBtnVisible = true;

  constructor(
    private dialogRef: MatDialogRef<WarningDeleteItemComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.itemIndex = this.data.index;
    this.info = this.data.modalInfo ? this.data.modalInfo : null;
    this.topicInfo = this.data.topicInfo ? this.data.topicInfo : null;
    this.msg = this.data.displayMsg ? this.data.displayMsg : 'Delete Item';
    this.btnText = this.data.btnText ? this.data.btnText : 'Confirm';
    this.dialogIcon = this.data.icon ? this.data.icon : 'assets/images/svg/error.svg';
    this.dialogIconClass = this.data.iconClass ? this.data.iconClass : '';
    this.isConfirmBtnVisible = 'isConfirmBtnVisible' in this.data ? this.data.isConfirmBtnVisible : true;
  }

  doAction() {
    this.dialogRef.close({ actionMode: 'DELETE', index: this.itemIndex, detail: this.info, topicInfo: this.topicInfo });
  }

  ngOnInit(): void {
  }

}
