import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Inject, Injectable, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import * as Sentry from "@sentry/browser";
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AskDoubtDialogComponent } from './content/dialog-box/ask-doubt-dialog/ask-doubt-dialog.component';
import { ErrorDialogComponent } from './content/dialog-box/error-dialog/error-dialog.component';
import { FeatureSupportDialogComponent } from './content/dialog-box/feature-support-dialog/feature-support-dialog.component';
import { LectureContentAlertDialogComponent } from './content/dialog-box/lecture-content-alert-dialog/lecture-content-alert-dialog.component';
import { ReportDialogComponent } from './content/dialog-box/report-dialog/report-dialog.component';
import { PlaceholderPipe, SafelPipe, SolutionDialogComponent } from './content/dialog-box/solution-dialog/solution-dialog.component';
import { StudentSelectionDialogComponent } from './content/dialog-box/student-selection-dialog/student-selection-dialog.component';
import { SubscriptionAlertDialogComponent } from './content/dialog-box/subscription-alert-dialog/subscription-alert-dialog.component';
import { TestModuleAlertDialogComponent } from './content/dialog-box/test-module-alert-dialog/test-module-alert-dialog.component';
import { CoreModule } from './core/core.module';
import { AccountManagementGuard } from './core/guards/account-management.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { PublicGuard } from './core/guards/public.guard';
import { HttpInterceptorProviders } from './core/interceptors';
import { InformationService } from './core/services/information.service';
import { SegmentAnalyticsService } from './core/services/segment-analytics.serrvice';
import { AngularMaterialModule } from './core/shared/angular-material.module';
import { SharedComponentsModule } from './core/shared/shared-componets.module';
import { PageDisplayStructureModule } from '@studentapp_pages/layout/page-display-structure/page-display-structure.module';
import { BranchProductConfiguratorResolver } from '@studentapp_core/resolvers/branch-product-loader/branch-product-configurator.resolver';
import { PictureUploaderModule } from '@studentapp_shared/utils-library/picture-uploader/picture-uploader.module';
import { userDetailInitializer } from '@studentapp_core/initializer/user-detail-init.initializer';
import { UserDataService } from '@studentapp_core/services/user-data.service';
import { LocalService } from '@studentapp_core/services/local.service';
import { availableProductsInitializer } from '@studentapp_core/initializer/available-products-init.initializer';
import { BranchProductService } from '@studentapp_core/services/branch-product.service';



// if (environment.production) {
//   Sentry.init({
//     dsn: environment.sentry_dsn
//   });
// }


// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() { }
//   handleError(error) {
//     const eventId = Sentry.captureException(error.originalError || error);
//     // Sentry.showReportDialog({ eventId });
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    StudentSelectionDialogComponent,
    SubscriptionAlertDialogComponent,
    LectureContentAlertDialogComponent,
    ReportDialogComponent,
    TestModuleAlertDialogComponent,
    SolutionDialogComponent,
    SafelPipe,
    PlaceholderPipe,
    AskDoubtDialogComponent,
    FeatureSupportDialogComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularMaterialModule,
    SharedComponentsModule,
    CoreModule,
    PageDisplayStructureModule,
    PictureUploaderModule,
  ],
  providers: [
    PublicGuard,
    AuthGuard,
    InformationService,
    AccountManagementGuard,
    BranchProductConfiguratorResolver,
    HttpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: SegmentAnalyticsService) => () => ds.load(),
      deps: [SegmentAnalyticsService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: userDetailInitializer,
      deps: [
        LocalService,
        UserDataService,
        HttpClient,
      ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: availableProductsInitializer,
      deps: [
        BranchProductService,
      ],
      multi: true
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: SentryErrorHandler
    // }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ReportDialogComponent,
    LectureContentAlertDialogComponent,
    ErrorDialogComponent,
    StudentSelectionDialogComponent,
    TestModuleAlertDialogComponent,
    SolutionDialogComponent,
    AskDoubtDialogComponent,
    FeatureSupportDialogComponent
  ]
})

export class AppModule {
  constructor(@Inject(DOCUMENT) private document: Document) {

    if (environment.production) {

      const stripe_key = document.getElementById('stripe_key');
      if (stripe_key) {
        stripe_key.innerHTML = `var stripe =  Stripe('pk_live_R8mMdAd9E5o3n9C9IRfKhEcN00l3lXwZgG');
        var elements = stripe.elements();`
      }

      this.document.body.classList.add('block-select');
      this.document.addEventListener('contextmenu', event => event.preventDefault());
    } else {

      const stripe_key = document.getElementById('stripe_key');
      if (stripe_key) {
        stripe_key.innerHTML = `var stripe =  Stripe('pk_test_8J5p7HTzRSDNe2qp5T4BSjoy00zz3D8Ky6');
        var elements = stripe.elements();`
      }

      this.document.body.classList.remove('block-select');
    }

  }
}
