import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './APIService/auth.service';
import { DataService } from './DataService/data.service';
import { PRODUCT_ROUTE_PATHS } from '@studentapp_core/common/constant';

@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnDestroy {

  subscription$: Subscription[] = [];

  students_data: any = null;

  public main_topic_index: number = 0;
  public sub_topic_index: number = 0;
  public lecture_index: number = 0;

  public isStudentSelectionDialogOpen: boolean = false;


  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    public dataService: DataService,
    public authService: AuthService,
  ) { }
  
  /* check an object is empty or not */
  isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  }


  /* set the student's data at the time of click on subscription button in student A/C management page */
  setStudentsData(students_data) {
    console.log({ students_data });
    this.students_data = students_data;
    console.log('set students data', this.students_data);
  }

  /* get the student's data for enrollment new or existing student */
  public getStudentsData() {
    // console.log('get students data', this.students_data);
    return this.students_data;
  }


  /* reset the students data to empty array */
  resetStudentsData() {
    this.students_data = [];
    console.log('reset students data', this.students_data);
  }







  /* get the selected lecture content data (video/text-content/test/quiz-bank components etc) */
  getLectureContent(lecture_id, router_obj, subtopic_obj, mainTopicId, subject_id, sub_route_path) {
    console.log({ lecture_id }, { router_obj }, { subtopic_obj }, { mainTopicId });
    const subTopicId = subtopic_obj ? subtopic_obj.id : null;
    this.authService.getLectureContent(lecture_id, false, subTopicId, mainTopicId, subject_id)
      .subscribe((lecture_content: any) => {

        if (lecture_content.content_info.length <= 0) return;

        /* check which lecture components are completed */
        lecture_content.content_info.forEach((element, i) => {
          let obj = element;
          if (element.progress_status === 1)
            element.completed = true;
          else
            element.completed = false;

          lecture_content.content_info.splice(i, 1, obj);
        });

        /* enable those lecture components which are completed and which one is in-progress */
        for (let i = 0; i < lecture_content.content_info.length; i++) {
          if (lecture_content.content_info[i].progress_status === 1 && i < (lecture_content.content_info.length - 1)) lecture_content.content_info[i + 1].progress_status = 1;
          if (lecture_content.length === 1) lecture_content[i].progress_status = 1;
          break;
        }

        console.log({ lecture_content });

        if (lecture_content.content_info) {
          this.dataService.setLectureContent(lecture_content);
          lecture_content = lecture_content.content_info;
        }

        if (lecture_content.length > 0) {
          switch (lecture_content[0].type) {
            case 'VIDEO': {
              this.dataService.setLectureContentData(lecture_content[0]);
              this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/video/${lecture_content[0].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
              break;
            }
            case 'QUIZBANK': {
              this.dataService.setLectureContentData(lecture_content[0]);
              this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/quiz/${lecture_content[0].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
              break;
            }
            case 'TEXT': {
              this.dataService.setLectureContentData(lecture_content[0]);
              this.router.navigate([`/${ sub_route_path }/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/text-content/${lecture_content[0].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
              break;
            }
            case 'TEST': {
              this.dataService.setLectureContentData(lecture_content[0]);
              this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/test/${lecture_content[0].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
              break;
            }
          }
        }
      }, error => console.error(error));
  }

  doAssignmentComponentRedirection(lecture_content: any, router_obj: any, sub_route_path) {
    switch (lecture_content.type) {
      case 'VIDEO': {
        this.dataService.setLectureContentData(lecture_content);
        this.router.navigate([`/${sub_route_path}/superbooks/assignment/${router_obj.params.asgId}/video/${lecture_content.id}`], { queryParams: { q_source: router_obj.queryParams.q_source } });
        break;
      }
      case 'QUIZBANK': {
        this.dataService.setLectureContentData(lecture_content);
        this.router.navigate([`/${sub_route_path}/superbooks/assignment/${router_obj.params.asgId}/quiz/${lecture_content.id}`], { queryParams: { q_source: router_obj.queryParams.q_source } });
        break;
      }
      case 'TEXT': {
        this.dataService.setLectureContentData(lecture_content);
        this.router.navigate([`/${sub_route_path}/superbooks/assignment/${router_obj.params.asgId}/text-content/${lecture_content.id}`], { queryParams: { q_source: router_obj.queryParams.q_source } });
        break;
      }
      case 'TEST': {
        this.dataService.setLectureContentData(lecture_content);
        this.router.navigate([`/${sub_route_path}/superbooks/assignment/${router_obj.params.asgId}/test/${lecture_content.id}`], { queryParams: { q_source: router_obj.queryParams.q_source } });
        break;
      }
    }
  }





  /* just a popup to show the title & description before continue with any lecture component */
  openectureContentAlertDialog(isClicked, index, lecture_content, router_obj, status, sub_route_path): void {
    // console.log({ router_obj }, { status });
    // const dialogRef = this.dialog.open(LectureContentAlertDialogComponent, {
    //   disableClose: true,
    //   width: '600px',
    //   data: lecture_content[index] ? lecture_content[index] : null
    // });
    // this.subscription$.push(dialogRef.afterClosed().subscribe(selectedStudent => {
    if (lecture_content.length > 0) {
      switch (lecture_content[index].type) {
        case 'VIDEO': {
          if (isClicked) {
            this.dataService.setLectureContentData(lecture_content[index]);
            this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/video/${lecture_content[index].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
          }
          return;
        }
        case 'QUIZBANK': {
          if (isClicked) {
            this.dataService.setLectureContentData(lecture_content[index]);
            this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/quiz/${lecture_content[index].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
          }
          return;
        }
        case 'TEXT': {
          if (isClicked) {
            this.dataService.setLectureContentData(lecture_content[index]);
            this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/text-content/${lecture_content[index].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
          }
          return;
        }
        case 'TEST': {
          if (isClicked) {
            this.dataService.setLectureContentData(lecture_content[index]);
            this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/test/${lecture_content[index].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
          }
          return;
        }
        case 'LESSON': {
          if (isClicked) {
            this.dataService.setLectureContentData(lecture_content[index]);
            this.router.navigate([`/${sub_route_path}/superbooks/${router_obj.params.subjectName}/${router_obj.params.mainTopicName}/${router_obj.params.mainTopicId}/logical-lesson/${lecture_content[index].id}`], { queryParams: { subject_id: router_obj.queryParams.subject_id, subject_name: router_obj.queryParams.subjectName, lecture_id: router_obj.queryParams.lecture_id, sub_topic_index: router_obj.queryParams.sub_topic_index } });
          }
          return;
        }
      }
    }
    // }));
  }


  /* get the time difference in second between start & end 
     for each lecture components (like VIDEO/TEXT/QUIZ etc...)
  */
  getTime(ended_at, started_at) {
    if (ended_at && started_at) return Math.round((ended_at - started_at) / 1000);
  }


  /* get the time as second and then return in HH:MM:SS format */
  public calculation(seconds, timeIn = 'HOURS') {
    // console.log(timeIn);

    let s: any = 0, m: any = 0, h: any = 0;
    s = seconds % 60;
    s = s > 9 ? s : `0${s}`;
    m = Math.floor(seconds / 60);
    if (m > 59) {
      h = Math.floor(m / 60);
      m = Math.floor(m % 60);
    }
    m = m > 9 ? m : `0${m}`;
    h = h > 9 ? h : `0${h}`;
    if (timeIn === 'HOURS') return {
      format: `${h}:${m}:${s}`
    }
    if (timeIn === 'MINUTES') return {
      format: `${m}:${s}`
    }
    if (timeIn === 'SECONDS') return {
      format: `${s}`
    }
  }


  ngOnDestroy() {
    console.log('ngOnDestroy called');
    if (this.subscription$.length > 0) {
      for (let subscription of this.subscription$) {
        if (subscription) subscription.unsubscribe();
      }
    }
  }


  /* check for currently login student subscription info */
  checkForStudentSubscription() {

    if (!this.authService.getCurrentStudentData()) return;
    const CurrentStudentData = this.authService.getCurrentStudentData();
    const subscription = this.authService.getCurrentStudentSubscription();
    const btn = (subscription && subscription.customer_plan.pricing_model === 'flat_fee' && subscription.customer_plan.is_active) ? 'Upgrade Now' : 'Subscribe Now';

    if (this.isEmpty(CurrentStudentData.student_subscription) || CurrentStudentData.student_subscription.status === 'cancelled') {
      let snackBarRef1 = this.snackBar.open(`please ${!subscription ? 'subscribe' : 'upgrade'} to get access`, btn, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end' });
      snackBarRef1.onAction().subscribe(() => {
        if (CurrentStudentData) {
          this.authService.setCurrentStudentData(CurrentStudentData);
          this.authService.set_UserAuthenticated();
          this.setStudentsData(CurrentStudentData);

          this.router.navigate(['', { outlets: { modal: 'management/enroll-students' } }], { queryParams: { action: 'subscription' }, queryParamsHandling: 'merge' });
        }
      });
    }

  }

  checkLoginStatusAndNavigate(extraInformation: any = null) {
    if(this.authService.getCurrentStudentData()) {
      return true;
    }
    else if (extraInformation) {
      this.router.navigate(['', { outlets: { modal: 'student/login' }}], extraInformation);
      return false;
    }
    else {
      this.router.navigate(['', { outlets: { modal: 'student/login' }}], { queryParamsHandling: 'preserve' });
      return false;
    }
  }

  

  
}
