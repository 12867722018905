<ng-container *ngFor="let _bottom_nav_item of bottomMenuItemsList">
    <a class="mf-btn" [routerLink]="['/' + subRoutePath + _bottom_nav_item.route_path]" routerLinkActive="active" [queryParamsHandling]="'preserve'">
        <img [src]="_bottom_nav_item.icon" alt="">
        <img [src]="_bottom_nav_item.hover_icon" alt="" class="sel">
        <span class="txt">{{ _bottom_nav_item.name }}</span>
    </a>
</ng-container>

<a class="mf-btn switch {{ activeProductSetting.product_route_path }}" href="javascript: void()" *ngIf="activeProductSetting" (click)="openProductSwitcherPopup()">
    <img class="menuImg no-inv widthimage22" [src]="activeProductSetting.product_icon_path">
    <span class="txt">{{ activeProductSetting.product_name }}</span>
</a>
<!-- <button class="mf-btn active">
    <img src="./assets/images/icons/home.png" alt="">
    <span class="txt">Home</span>
</button>
<button class="mf-btn">
    <img src="./assets/images/icons/virtual-event.png" alt="">
    <span class="txt">Classes</span>
</button>
<button class="mf-btn">
    <img src="./assets/images/icons/invoice.png" alt="">
    <span class="txt">Exam</span>
</button>
<button class="mf-btn">
    <img src="./assets/images/icons/to-do-list.png" alt="">
    <span class="txt">Work Sheet</span>
</button>
<button class="mf-btn">
    <img src="./assets/images/icons/online-learning.png" alt="">
    <span class="txt">Learn</span>
</button> -->