export const WHITEBORAD_DEFAULT_WIDTH = 1366;
export const WHITEBORAD_DEFAULT_HEIGHT = 768;

export const LEARN_PAGE_RIGHT_PANEL_ID = 'learn-table-of-content-details';

export const TEACHER_DEFAULT_IMAGE = 'assets/images/user.png';
export const TEACHER_BIG_CARD_DEFAULT_IMAGE = 'assets/images/teacher-male-default-photo.png';
export const TEACHER_BIG_CARD_DEFAULT_FEMALE_IMAGE = 'assets/images/teacher-female-default-photo.png';
export const STUDENT_DEFAULT_IMAGE = 'assets/images/user.png';
export const MAIN_TOPIC_DEFAULT_IMAGE = 'assets/images/science.png';
export const ASSIGNMENT_DEFAULT_IMAGE = 'assets/images/assignment-icon.png';

export const PRODUCT_CATEGORY_MAP = {
    doerscore: 1,
    aeiser: 34
};

export const REFER_SOURCE_SESSION_STORAGE_KEY = 'refer-source-value';
export const REFER_SOURCE_VALUE_WHITE_LiSTED = [ 'doerscore', 'aeiser' ];
export const REFER_SOURCE_PRODUCT_CATEGORY_MAP = PRODUCT_CATEGORY_MAP;

export const CLASS_STATUS_PRIORITIES = {
    'ongoing': 1,
    'not_started': 3,
    'ended': 2
};

export const STUDENT_GENDERS: any = [
    {
      id: 'male',
      name: 'Male'
    },
    {
      id: 'female',
      name: 'Female'
    }
];

export const DOERDO_PRODUCT_PAGE_BOTTOM_MENU_SETTING = [
    {
        id: 'doerDo',
        name: 'Learn',
        icon: 'assets/images/icons/online-learning.png',
        hover_icon: 'assets/images/icons/online-learning-fill.png',
        route_path: '/learn/home',
    },
    {
        id: 'doerDo',
        name: 'Sheet',
        icon: 'assets/images/icons/invoice.png',
        hover_icon: 'assets/images/icons/invoice-fill.png',
        route_path: '/work-sheet/listing',
    },
    {
        id: 'doerDo',
        name: 'Exam',
        icon: 'assets/images/icons/to-do-list.png',
        hover_icon: 'assets/images/icons/to-do-list-fill.png',
        route_path: '/mock-exams/listing',
    },
    {
        id: 'doerDo',
        name: 'Live Course',
        icon: 'assets/images/icons/live-class.png',
        hover_icon: 'assets/images/icons/live-class-fill.png',
        route_path: '/my-courses/home',
    },
    // {
    //     id: 'doerDo',
    //     name: 'My Courses',
    //     icon: 'assets/images/icons/invoice.png',
    //     hover_icon: 'assets/images/icons/invoice-fill.png',
    //     route_path: '/my-courses/home',
    // },
];

export const GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING = [
    {
        id: 'doerDo',
        name: 'Explore Courses',
        icon: 'assets/images/icons/virtual-event.png',
        hover_icon: 'assets/images/icons/virtual-event-fill.png',
        route_path: '/live-courses/home',
    },
    {
        id: 'doerDo',
        name: 'My Courses',
        icon: 'assets/images/icons/live-class.png',
        hover_icon: 'assets/images/icons/live-class-fill.png',
        route_path: '/my-courses/home',
    },
];

export const DOERDO_PRODUCT_PAGE_LEFT_MENU_SETTING = [
    { id: null, menu_code: 'LEARN', visible: true, enabled: true },
    { id: null, menu_code: 'LIVE_COURSE', visible: true, enabled: true },
    { id: null, menu_code: 'MESSAGE', visible: true, enabled: true },
    { id: null, menu_code: 'CALENDAR', visible: true, enabled: true },
    { id: null, menu_code: 'TASK', visible: true, enabled: false },
    { id: null, menu_code: 'DOER_DRIVE', visible: true, enabled: false },
    { id: null, menu_code: 'LIBRARY', visible: true, enabled: false },
    { id: null, menu_code: 'FINANCE', visible: true, enabled: false },
];

export const GENERAL_PRODUCT_PAGE_LEFT_MENU_SETTING = [
    { id: null, menu_code: 'LEARN', visible: false, enabled: false },
    { id: null, menu_code: 'LIVE_COURSE', visible: true, enabled: true },
    { id: null, menu_code: 'CALENDAR', visible: true, enabled: true },
    { id: null, menu_code: 'MESSAGE', visible: true, enabled: false },
    { id: null, menu_code: 'TASK', visible: true, enabled: false },
    { id: null, menu_code: 'DOER_DRIVE', visible: true, enabled: false },
    { id: null, menu_code: 'LIBRARY', visible: true, enabled: false },
    { id: null, menu_code: 'FINANCE', visible: true, enabled: false },
];

export type PRODUCTS_AVAILABLE = 'doerDo'| 'doerAeiser' | 'doerCode' | 'doerSpark' | 'doerMath' | 'doerTalk' | 'doerCampus';
export const PRODUCT_NAMES: {[key in PRODUCTS_AVAILABLE]: string} = {
    doerDo: 'DoerDo',
    doerAeiser: 'Aeiser',
    doerCode: 'DoerCode',
    doerSpark: 'DoerSpark',
    doerMath: 'DoerMath',
    doerTalk: 'DoerTalk',
    doerCampus: 'DoerCampus',
};

export const PRODUCT_HEADINGS: {[key in PRODUCTS_AVAILABLE]: string} = {
    doerDo: 'Do',
    doerAeiser: 'Aeiser',
    doerCode: 'Code',
    doerSpark: 'Spark',
    doerMath: 'Math',
    doerTalk: 'Talk',
    doerCampus: 'Campus',
};

export const PRODUCT_ROUTE_PATHS: {[key in PRODUCTS_AVAILABLE]: string} = {
    doerDo: 'doer-do',
    doerAeiser: 'aeiser',
    doerCode: 'doer-code',
    doerSpark: 'doer-spark',
    doerMath: 'doer-math',
    doerTalk: 'doer-talk',
    doerCampus: 'doer-campus',
};

export const DOERDO_PRODUCT_SETTTING = {
    product_type: 'doerDo',
    product_name: 'DoerScore',
    product_heading: 'doerDo',
    product_heading_html: `&nbsp;<span>doer<strong>Do</strong></span>`,
    product_route_path: 'doer-do',
    product_default_path: '/doer-do/learn',
    product_icon_path: 'assets/images/icons/doerdo-logo-icon.png',
    product_full_logo_path: 'assets/images/icons/product_doerScore_logo_4x.png',
    product_category_id: PRODUCT_CATEGORY_MAP['doerscore'],
    grade_id_default_storage_key: 'doerdo_default_grade_id',
    subject_id_default_storage_key: 'doerdo_default_subject_id',
    subject_category_id_default_storage_key: 'doerdo_default_subject_category_id',
    grade_id_live_course_storage_key: 'doerdo_live_course_grade_id',
    subject_id_live_course_storage_key: 'doerdo_live_course_subject_id',
    manual_product_selection_allowed: true,
    bottom_page_menu_settings: DOERDO_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    left_menu_setting: DOERDO_PRODUCT_PAGE_LEFT_MENU_SETTING,
};

export const DOERAEISER_PRODUCT_SETTTING = {
    product_type: 'doerAeiser',
    product_name: 'Aeiser',
    product_heading: 'Aeiser',
    product_heading_html: `&nbsp;<span>Aeiser</span>`,
    product_route_path: 'doer-aeiser',
    product_default_path: '/doer-aeiser/learn',
    product_icon_path: 'assets/images/icons/doeraeiser_logo_icon.png',
    product_full_logo_path: 'assets/images/icons/product_doerAeiser_logo_4x.png',
    product_category_id: PRODUCT_CATEGORY_MAP['aeiser'],
    grade_id_default_storage_key: 'doeraeiser_default_grade_id',
    subject_id_default_storage_key: 'doeraeiser_default_subject_id',
    subject_category_id_default_storage_key: 'doeraeiser_default_subject_category_id',
    grade_id_live_course_storage_key: 'doeraeiser_live_course_grade_id',
    subject_id_live_course_storage_key: 'doeraeiser_live_course_subject_id',
    manual_product_selection_allowed: true,
    bottom_page_menu_settings: DOERDO_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    left_menu_setting: DOERDO_PRODUCT_PAGE_LEFT_MENU_SETTING,
};

export const DOERCODE_PRODUCT_SETTTING = {
    product_type: 'doerCode',
    product_name: 'DoerCode',
    product_heading: 'doerCode',
    product_heading_html: `&nbsp;<span>doer<strong>Code</strong></span>`,
    product_route_path: 'doer-code',
    product_default_path: '/doer-code/my-courses',
    product_icon_path: 'assets/images/icons/doercode_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerCode_logo_4x.png',
    grade_id_live_course_storage_key: 'doercode_live_course_grade_id',
    subject_id_live_course_storage_key: 'doercode_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    left_menu_setting: GENERAL_PRODUCT_PAGE_LEFT_MENU_SETTING,
};

export const DOERSPARK_PRODUCT_SETTTING = {
    product_type: 'doerSpark',
    product_name: 'DoerSpark',
    product_heading: 'doerSpark',
    product_heading_html: `&nbsp;<span>doer<strong>Spark</strong></span>`,
    product_route_path: 'doer-spark',
    product_default_path: '/doer-spark/my-courses',
    product_icon_path: 'assets/images/icons/doerspark_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerSpark_logo_4X.png',
    grade_id_live_course_storage_key: 'doerspark_live_course_grade_id',
    subject_id_live_course_storage_key: 'doerspark_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    left_menu_setting: GENERAL_PRODUCT_PAGE_LEFT_MENU_SETTING,
};

export const DOERMATH_PRODUCT_SETTTING = {
    product_type: 'doerMath',
    product_name: 'DoerMath',
    product_heading: 'doerMath',
    product_heading_html: `&nbsp;<span>doer<strong>Math</strong></span>`,
    product_route_path: 'doer-math',
    product_default_path: '/doer-math/my-courses',
    product_icon_path: 'assets/images/icons/doermath_logo_icon_green_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerMath_logo_4x.png',
    grade_id_live_course_storage_key: 'doermath_live_course_grade_id',
    subject_id_live_course_storage_key: 'doermath_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    left_menu_setting: GENERAL_PRODUCT_PAGE_LEFT_MENU_SETTING,
};

export const DOERTALK_PRODUCT_SETTTING = {
    product_type: 'doerTalk',
    product_name: 'DoerTalk',
    product_heading: 'doerTalk',
    product_heading_html: `&nbsp;<span>doer<strong>Talk</strong></span>`,
    product_route_path: 'doer-talk',
    product_default_path: '/doer-talk/my-courses',
    product_icon_path: 'assets/images/icons/doertalk_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerTalk_logo_4x.png',
    grade_id_live_course_storage_key: 'doertalk_live_course_grade_id',
    subject_id_live_course_storage_key: 'doertalk_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    left_menu_setting: GENERAL_PRODUCT_PAGE_LEFT_MENU_SETTING,
};

export const DOERCAMPUS_PRODUCT_SETTTING = {
    product_type: 'doerCampus',
    product_name: 'DoerCampus',
    product_heading: '',
    product_heading_html: ``,
    product_route_path: 'doer-campus',
    product_default_path: '/doer-campus/my-courses',
    product_icon_path: 'assets/images/icons/doercampus_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerCampus_logo_4x.png',
    grade_id_live_course_storage_key: 'doercampus_live_course_grade_id',
    subject_id_live_course_storage_key: 'doercampus_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    left_menu_setting: GENERAL_PRODUCT_PAGE_LEFT_MENU_SETTING,
};

export const PRODUCT_SETTING_DATA: {[key in PRODUCTS_AVAILABLE]: any} = {
    doerDo: DOERDO_PRODUCT_SETTTING,
    doerAeiser: DOERAEISER_PRODUCT_SETTTING,
    doerCode: DOERCODE_PRODUCT_SETTTING,
    doerSpark: DOERSPARK_PRODUCT_SETTTING,
    doerMath: DOERMATH_PRODUCT_SETTTING,
    doerTalk: DOERTALK_PRODUCT_SETTTING,
    doerCampus: DOERCAMPUS_PRODUCT_SETTTING,
};

export const PRODUCT_VISIBILITY_DEFAULT_ORDER_DATA: any[] = [
    { id: null, product_code: 'doerDo', product_setting: null, },
    { id: null, product_code: 'doerAeiser', product_setting: null, },
    { id: null, product_code: 'doerCode', product_setting: null, },
    { id: null, product_code: 'doerSpark', product_setting: null, },
    { id: null, product_code: 'doerMath', product_setting: null, },
    { id: null, product_code: 'doerTalk', product_setting: null, },
    { id: null, product_code: 'doerCampus', product_setting: null, },
];

export const LEFT_MENU_FLAG_VALUE = 'LEFT_NAVBAR';
export const BOTTOM_MENU_FLAG_VALUE = 'BOTTOM_NAVBAR';
export const TOP_MENU_FLAG_VALUE = 'TOP_NAVBAR';

export const LIVE_COURSE_STATISTICS_ITEMS = [
    {
        name: 'Ongoing Courses',
        field: 'ongoing_courses',
        panelClass: '',
        iconPath: 'assets/images/svg/ongoing.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'Course Completed',
        field: 'completed_courses',
        panelClass: 'red',
        iconPath: 'assets/images/svg/completed.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'Class Attended',
        field: 'completed_classes',
        panelClass: 'purple',
        iconPath: 'assets/images/svg/checklist.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'This Week Classes',
        field: 'this_week_classes',
        panelClass: 'blue',
        iconPath: 'assets/images/svg/week-list.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'Open Assignments',
        field: 'open_assignments',
        panelClass: 'seafoam-green',
        iconPath: 'assets/images/svg/assignment.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
];

export const TOPIC_AVAILABILITIES = [
    { id: 0, name: "Active", cssClass: 'active' },
    { id: 2, name: "Backend-only", cssClass: 'warning' },
    { id: 1, name: "Inactive", cssClass: 'danger' },
];

export const TOPIC_MODES = [
    { id: 'learn', name: 'Learn' },
    { id: 'revision', name: 'Revision' },
    { id: 'test', name: 'Test' },
];

export const TOPIC_INTRO_TYPES = [
    { id: true, name: 'Show', cssClass: 'active' },
    { id: false, name: 'Hide', cssClass: 'danger' },
];

export const TOPIC_NEXT_ITEM_MAP = {
    SB: { label: 'Main Topic', cssClass: 'w-85', nextType: 'MT' },
    MT: { label: 'Sub Topic', cssClass: 'w-80', nextType: 'ST' },
    ST: { label: 'Lecture', cssClass: 'w-70', nextType: 'Lec' },
    Lec: { label: 'Lec Component', cssClass: 'w-110', nextType: 'Lec_Comp' },
};

export const TOPIC_COMPONENT_RELATED_INFO_MAP = {
    'LP': {
        items: [ 'LP' ],
    },
    'SB': {
        items: [ 'SB' ],
    },
    'MT': {
        items: [ 'MT' ],
    },
    'ST': {
        items: [ 'ST' ],
    },
    'Lec': {
        items: [ 'Lec' ],
    },
    'Lec_Comp': {
        items: [ 'TEXT', 'QUIZBANK', 'TEST', 'LESSON', 'VIDEO' ],
    },
    'QUIZBANK': {
        items: [ 'QUIZBANK' ],
    }
};

export const SKIP_ALL_KIND_RESOLVER_API_CALL = {
    skipGradesListFromBackend: true,
    skipCourseTypesListFromBackend: true,
}

export const RED_COLOR_COMBO = {
    backgroundColor: '#ffd8e1',
    textColor: '#ed345a',
};

export const GREEN_COLOR_COMBO = {
    backgroundColor: '#84f5e3',
    textColor: '#19a78d',
};

export const YELLOW_COLOR_COMBO = {
    backgroundColor: '#f4e367',
    textColor: '#fd8925',
};

export const ORANGE_COLOR_COMBO = {
    backgroundColor: '#bcdff8',
    textColor: '#1996f2',
};

export const CHANNEL_MODEL_OPTIONS = [
    { id: 'THREAD', name: 'Thread' },
    { id: 'CHAT', name: 'Chat' },
];

export const CHANNEL_MESSAGE_TYPE_OPTIONS = [
    { id: 'Chat', name: 'Chat', icon: 'assets/images/svg/msg-type/chat.svg', cssClass: 'w-4ch' },
    { id: 'Thread', name: 'Thread', icon: 'assets/images/svg/msg-type/thread.svg', cssClass: 'w-6ch' },
    { id: 'Announcement', name: 'Announcement', icon: 'assets/images/svg/msg-type/announcement.svg', cssClass: 'w-12ch' },
    { id: 'Doubts', name: 'Doubts', icon: 'assets/images/svg/msg-type/doubts.svg', cssClass: 'w-6ch' },
    { id: 'Feedback', name: 'Feedback', icon: 'assets/images/svg/msg-type/feedback1.svg', cssClass: 'w-8ch' },
];

export const CHANNEL_TYPE_OPTIONS = [
    { id: 'PUBLIC', name: 'Public' },
    { id: 'PRIVATE', name: 'Private' },
    { id: 'one_to_one', name: 'One to One' }
];

export const CHANNEL_MEMBER_USER_TYPES_OPTIONS = [
    { id: 'STAFF', name: 'Staff', cssClass: '', icon: 'assets/images/svg/teacher-blue.svg' },
    { id: 'STUDENT', name: 'Student', cssClass: '', icon: 'assets/images/svg/student-blue.svg' },
    { id: 'PARENT', name: 'Parent', cssClass: '', icon: 'assets/images/svg/staff-icon.svg' },
];

export const CHANNEL_MEMBER_ROLE_EXCEPT_LEADER_OPTIONS = [
    // { id: 'LEADER', name: 'Leader' },
    { id: 'ADMIN', name: 'Admin', cssClass: '', icon: null, memberRoleChangeAllowed: ['ADMIN', 'MANAGER', 'MEMBER'], memberMoveAllowed: ['ADMIN', 'MANAGER', 'MEMBER'] },
    { id: 'MANAGER', name: 'Manager', cssClass: '', icon: null, memberRoleChangeAllowed: ['MANAGER', 'MEMBER'], memberMoveAllowed: ['MEMBER'] },
    { id: 'MEMBER', name: 'Member', cssClass: '', icon: null, memberRoleChangeAllowed: [], memberMoveAllowed: [] },
];

export const CHANNEL_MEMBER_FILTER_LEVEL_OPTIONS = [
    { id: 'TEAM_LEVEL_FILTER', name: "Team Level" },
    { id: 'CHANNEL_LEVEL_FILTER', name: "Channel Level" },
];

export const USER_POSSIBLE_ONLINE_STATUS = [
    { id: 'Available', name: 'Available', cssClass: 'available', icon: 'assets/images/svg/status-svgs/available.svg' },
    { id: 'Busy', name: 'Busy', cssClass: 'busy', icon: 'assets/images/svg/status-svgs/busy.svg' },
    { id: 'Do_not_distrub', name: 'Do not distrub', cssClass: 'do_not_distrub', icon: 'assets/images/svg/status-svgs/dnd.svg' },
    { id: 'Away', name: 'Appear Away', cssClass: 'away', icon: 'assets/images/svg/status-svgs/away.svg' },
    { id: 'Offline', name: 'Appear Offline', cssClass: 'offline', icon: 'assets/images/svg/status-svgs/offline.svg' },
];
