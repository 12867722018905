import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalService } from '@studentapp_core/services/local.service';
import { environment } from '@studentapp_env/environment';

@Component({
  selector: 'app-doerschool-branch-selection-dialog',
  templateUrl: './doerschool-branch-selection-dialog.component.html',
  styleUrls: ['./doerschool-branch-selection-dialog.component.scss']
})
export class DoerschoolBranchSelectionDialogComponent implements OnInit {

  currentStudentData = null;
  currentSelectedDoerSchoolBranch = null;
  branchesList: any[] = [];

  env = environment;
  imagePath = this.env.cdn_urls.image;

  constructor(
    private localService: LocalService,
    public dialogRef: MatDialogRef<DoerschoolBranchSelectionDialogComponent>,
  ) { }
  
  getSeletedBranch(_branch_index: number) {
    const _branch_info = this.branchesList[_branch_index];
    this.localService.setCurrentDoerCampusBranchInfo(_branch_info);
    this.dialogRef.close({ status: 'BRANCH_CHANGE' });
  }

  ngOnInit(): void {
    this.currentStudentData = this.localService.getCurrentStudentData();
    this.currentSelectedDoerSchoolBranch = this.localService.getCurrentDoerCampusBranchInfo();
    if (this.currentStudentData && this.currentStudentData.branch_data && this.currentStudentData.branch_data.length) {
      this.branchesList = this.currentStudentData.branch_data;
    }
  }

}
