<a href="javascript:void(0);" class="pop-close" (click)="dialogRef.close();">
    <img src="assets/images/svg/close.svg" alt="">
</a>
<div class="all-ac-list">
    <div class="ac-image-c with-check"  *ngFor="let _branch_data of branchesList ; let i = index">
        <label class="u-photo school-u-photo">
            <input type="radio" name="_branch_data_list" [value]="_branch_data.id" [checked]="_branch_data.id == currentSelectedDoerSchoolBranch?.id" (change)="getSeletedBranch(i)" [disabled]="isDisabled">
            <img [src]="imagePath + _branch_data.branch_logo" alt="" *ngIf="(_branch_data.branch_logo); else showOrgLogo">
            <ng-template #showOrgLogo>
                <img [src]="imagePath + _branch_data.org_id.org_logo" alt="" *ngIf="(_branch_data.org_id.org_logo) else showDefaultLogo">
            </ng-template>
            <ng-template #showDefaultLogo>
                <img src="assets/images/icons/organization-icon.png" alt="">
            </ng-template>            
            <span class="change">
                <img src="assets/images/svg/tick.svg" alt="">
            </span>
        </label>
        <span class="nm">{{ _branch_data.branch_code ? _branch_data.branch_code + ' | ' : '' }} {{_branch_data.branch_name}}</span>
        <span class="subc">({{_branch_data.org_id?.org_name }})</span>
    </div>
</div>