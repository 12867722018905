import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { SegmentAnalyticsService } from 'src/app/core/services/segment-analytics.serrvice';
import { AuthService } from '../../../core/services/APIService/auth.service';
import { DataService } from '../../../core/services/DataService/data.service';
import { StudentSelectionDialogComponent } from '../../dialog-box/student-selection-dialog/student-selection-dialog.component';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { LeftMenuHandlerService } from '@studentapp_core/services/left-menu-handler.service';
import { ProductNavigationService } from '@studentapp_core/services/product-navigation.service';
import { ReferalSourceDetectService } from '@studentapp_core/services/referal-source-detect.service';
import { SKIP_ALL_KIND_RESOLVER_API_CALL } from '@studentapp_core/common/constant';
import { environment } from '@studentapp_env/environment';
import { DoerschoolBranchSelectionDialogComponent } from '@studentapp_pages/dialog-box/doerschool-branch-selection-dialog/doerschool-branch-selection-dialog.component';
import { LocalService } from '@studentapp_core/services/local.service';
import { UserDataService } from '@studentapp_core/services/user-data.service';
import { BranchProductService } from '@studentapp_core/services/branch-product.service';
import { AccountSelectionDialogComponent } from '@studentapp_pages/dialog-box/account-selection-dialog/account-selection-dialog.component';


@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'app-nav-panel'
      }
    }
  ]
})
export class LeftSidebarComponent implements OnInit, OnDestroy {

  subscription$: Subscription[] = [];

  @Input() disabled: boolean = false;
  @Input() productInfo: string = '';
  @Input() userLoginHidden: boolean = true;
  @Input() subRoutePath = '';

  // @ViewChild('btn_menuOpen') btn_menuOpen: ElementRef;
  // @ViewChild('leftsidebar') leftsidebar: ElementRef;

  currentAccountData: any = null;
  currentStudentData: any = null;
  activeBranchInfo: any = null;
  availableBranchesList: any[] = [];

  env = environment;
  imagePath = this.env.cdn_urls.image;

  resolverSkipObjectData = SKIP_ALL_KIND_RESOLVER_API_CALL;
  productAvailablesList = [];
  currentActiveProductDefaultPath = '';
  routesLastChangedAt = Date.now();

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private authService: AuthService,
    private localService: LocalService,
    private userDataService: UserDataService,
    public dataService: DataService,
    public commonService: CommonService,
    private productNavigationService: ProductNavigationService,
    private segmentService: SegmentAnalyticsService,
    private leftMenuService: LeftMenuHandlerService,
    private referSourceDetectService: ReferalSourceDetectService,
    private branchProductService: BranchProductService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router) {

      setTimeout(() => {
        const a = router.isActive('/doer-do', { matrixParams: 'ignored', queryParams: 'ignored', paths: 'subset', fragment: 'ignored'});
        console.log('ROUTER ACTIVE', a);
      }, 10000);

    
  }

  resetStudentSelection() {
    this.dataService.updateStudentSelection([]);
  }

  checkClassExists(elem: HTMLElement, className: string) {
    return elem.classList.contains(className);
  }

  showDropMenu(event: any) {
    event.target.closest('.drop-menu-item-set').classList.add('show-menu');
  }

  toggleDropMenu(event: any) {
    event.target.closest('.drop-menu-item-set').classList.toggle('show-menu');
  }
  
  // toggleLeftNavMenu(event: any) {
  //   event.target.closest('.app-nav-container').classList.toggle('app-nav-collapse');
  // }

  /* toggle the left menu bar */
//   toggle() {
//     if (this.leftsidebar.nativeElement.classList.contains('out')) {
//       this.btn_menuOpen.nativeElement.animate([
//     { opacity: 1, offset: 0 },
//     { opacity: 0, offset: 1 }
// ], {
//     duration: 1000,
//     iterations: 1,
//     easing: 'ease',
//     fill: 'both'
// });
//       this.leftsidebar.nativeElement.classList.remove('out');
//       this.leftsidebar.nativeElement.classList.add('in');
//       document.body.classList.add('open-sidebar');
//     } else {

//       document.body.classList.remove('open-sidebar');
//       this.btn_menuOpen.nativeElement.animate([
//     { opacity: 0, offset: 0 },
//     { opacity: 1, offset: 1 }
// ], {
//     duration: 1000,
//     iterations: 1,
//     easing: 'ease',
//     fill: 'both'
// });
//       this.leftsidebar.nativeElement.classList.add('out');
//       this.leftsidebar.nativeElement.classList.remove('in');
//     }
//   }

  async forceRefreshToHomePage() {
    if (this.currentActiveProductDefaultPath) {
      return await this.productNavigationService.navigateToDefaultHomePage(this.currentActiveProductDefaultPath);
    }
    await this.productNavigationService.navigateToDefaultHomePage();
  }

  clearLastVisitedMenuSettings() {
    this.leftMenuService.deleteLastVisitedMenuLevelFromSessionStorage();
  }

  /* update sessonStorage with selected student whose subscription is ACTIVE */
  // openStudentSelectionDialog() {
  //   let CurrentUser = this.localService.getCurrentUser();
  //   if (CurrentUser) {
  //     const dialogRef = this.dialog.open(StudentSelectionDialogComponent, {
  //       width: '600px',
  //       data: {
  //         user: CurrentUser.user,
  //         students: CurrentUser.process.students,
  //       },
  //     });
  //     dialogRef.afterClosed().subscribe(selectedStudent => {
  //       if (!selectedStudent || (this.localService.getCurrentStudentData() && this.localService.getCurrentStudentData().id === selectedStudent.id)) return;
  //       // if ((selectedStudent.student_subscription !== {} && selectedStudent.student_subscription.status === 'active')) {
  //       this.localService.setCurrentStudentData(selectedStudent);
  //       this.localService.removeCurrentDoerCampusBranchInfo();
  //       let snackBarRef = this.snackBar.open(`loggedin with student - ${selectedStudent.name}`, null, { duration: 2000, verticalPosition: 'top', horizontalPosition: 'end' });
  //       snackBarRef.afterDismissed().subscribe(() => {
  //         // this.dataService.is_storageUpdate(true);
  //         // this.cd.detectChanges();
  //         // localStorage.removeItem('selected_grade_id');
  //         // localStorage.removeItem('selected_subject_id');
  //         this.forceRefreshToHomePage();
  //       });
  //       // } else {
  //       //   this.snackBar.open(`there is no active subscription for student - ${selectedStudent.name}`, null, { duration: 5000, verticalPosition: 'top', horizontalPosition: 'end' });
  //       // }
  //     });
  //   }
  // }

  openSwitchSchoolBranchSelection() {
    if (this.availableBranchesList.length > 1) {
      const dialogRef = this.dialog.open(DoerschoolBranchSelectionDialogComponent, {
        width: '800px',
        data: {
          
        },
      });
  
      dialogRef.afterClosed().subscribe(
        (response: any) => {
          if (response) {
            if (response.status === 'BRANCH_CHANGE') {
              location.reload();
            }
          }
        }
      )
    }
  }

  // login detail containing email and username, either one is required for login
  async redirectToLoginPageToDoManualLoginRequest(_login_detail: any) {
    this.logout(false);
    // ENABLED AUTO LOGIN
    this.localService.enableAutoLoginBySessionStorage();
    // store login detail in storage
    this.localService.storeUserInputAtRegistration(_login_detail);
    this.router.navigate(['', { outlets: { modal: 'student/login' } }], { queryParamsHandling: 'preserve', relativeTo: this.activatedRoute });
  }

  async doAutomaticallyLoginAfterSuccessFulSwitchRequest(_login_detail: any) {
    this.logout(false);
    // ENABLED DIRECT LOGIN
    this.localService.enableDirectLoginBySessionStorage();
    // store DIRECT login detail in storage
    this.localService.storeDirectLoggedInformationAsSwitchFeature(_login_detail);
    this.router.navigate(['', { outlets: { modal: 'student/login' } }], { queryParamsHandling: 'preserve', relativeTo: this.activatedRoute });
  }

  openSwitchAccountSelectionDialog() {
    let CurrentUser = this.localService.getCurrentUser();
    if (CurrentUser) {
      const dialogRef = this.dialog.open(AccountSelectionDialogComponent, {
        width: '600px',
        data: {
          user: CurrentUser.user,
          students: CurrentUser.process.students,
        },
      });
      dialogRef.afterClosed().subscribe(
        (response) => {
          if (response && response.status === 'success') {
            if (response.action === 'logout_from_current_auto_new_login') {
              this.doAutomaticallyLoginAfterSuccessFulSwitchRequest(response.login_detail);
            }
            if (response.action === 'logout_from_current_manual_login') {
              this.redirectToLoginPageToDoManualLoginRequest(response.login_detail);
            }
          }
      });
    }
  }


  /* logout and redirect to the student login page */
  logout(_navigate_home_page = true) {
    localStorage.removeItem('CurrentUser');
    localStorage.removeItem('selected_grade_id');
    localStorage.removeItem('selected_subject_id');
    
    this.localService.removeTemporaryAccountInfoBeforeLogin();
    this.localService.removeUserInputAtRegistration();
    this.localService.removeTemporaryUserInfoAfterRegistration();
    this.localService.removeTemporaryStudentInfoAfterStudentRegistration();

    this.authService.removeCurrentStudentData();
    this.dataService.update_storage(true);
    this.segmentService.reset();
    this.referSourceDetectService.removeReferalSourceValueFromSessionStorage();
    this.localService.removeCurrentDoerCampusBranchInfo();
    if (_navigate_home_page) {
      this.forceRefreshToHomePage();
    }
  }

  subscription(){
    if(!this.commonService.checkLoginStatusAndNavigate()) {
      return;
    }
    const CurrentStudentData = this.localService.getCurrentStudentData();
    console.log(CurrentStudentData)
    if (CurrentStudentData) {
      this.localService.setCurrentStudentData(CurrentStudentData);
      this.authService.set_UserAuthenticated();
      this.commonService.setStudentsData(CurrentStudentData);
      if(Object.keys(CurrentStudentData.student_subscription).length === 0 && CurrentStudentData.student_subscription.constructor === Object){
        // this.router.navigate(['/management/enroll-students'], { queryParams: { action: 'subscription' } });
        this.router.navigate(['', { outlets: { modal: 'management/enroll-students' } }], { queryParams: { action: 'subscription' }, queryParamsHandling: 'merge', state: this.resolverSkipObjectData });
      }
      else{
        // this.router.navigate(['/management/account/student']);
        this.router.navigate(['', { outlets: { modal: 'management/account/student' } }], { queryParamsHandling: 'preserve', state: this.resolverSkipObjectData });
      }
     
    }
    
  }


  /* check for new or unread messages count for all doubt */
  getUnreadMsgCount() {

    if (!this.currentStudentData) return;

    const OBJ = {
      student_id: this.currentStudentData.id,
      recipient: 'teacher'
    }

    this.authService.getAllDoubtsUnreadMessageCount(OBJ).subscribe((response: any) => {
      if (response.isError) return;
      if (response.data && response.data.length === 0) return;
      let doubts = response.data ? response.data : [];
      let totalUnreadMsg = 0;
      for (let doubt of doubts)
        totalUnreadMsg += doubt.unread_message;

      this.dataService.setUnreadMsgCount(totalUnreadMsg);
    },
      error => console.error);
  }

  /* check for new or unread conversations count for all tickets */
  getUnreadConversationCount() {

    const customer_id = this.localService.getCurrentUser() ?
      this.localService.getCurrentUser().user.id :
      null;

    if (!customer_id) return;

    const OBJ = {
      customer_id: customer_id,
      recipient: 'support_team'
    }

    this.authService.getAllTicketsUnreadConversationCount(OBJ).subscribe((response: any) => {
      if (response.isError) return;
      if (response.data && response.data.length === 0) return;
      let tickets = response.data ? response.data : [];
      let totalUnreadConversations = 0;
      for (let ticket of tickets)
        totalUnreadConversations += ticket.unread_conversation;

      this.dataService.setUnreadConversationCount(totalUnreadConversations);
    },
      error => console.error);
  }


  /* get the currently loggedin student's data */
  getCurrentStudentData() {
    this.currentAccountData = this.localService.getCurrentUserData();
    if (this.localService.getCurrentStudentData()) {
      this.currentStudentData = this.localService.getCurrentStudentData();
      this.availableBranchesList = [];
      if (this.currentStudentData && this.currentStudentData.branch_data && this.currentStudentData.branch_data.length) {
        this.availableBranchesList = this.currentStudentData.branch_data;
      }
    } else {
      this.currentStudentData = null;
      this.availableBranchesList = [];
    }
    this.activeBranchInfo = this.localService.getCurrentDoerCampusBranchInfo();
  }

  // remove_nav() {
  //   document.body.classList.add("hide-main-nav");
  //   document.getElementById('overlay-superbook').classList.remove('open');
  // }

  ngOnInit() {
    this.getCurrentStudentData();
    this.getUnreadMsgCount();
    this.getUnreadConversationCount();

    this.subscription$.push(
      this.dataService.StorageUpdate$.subscribe((storageUpdate: Boolean) => {
        if (storageUpdate) {
          console.log({ storageUpdate });
          this.getCurrentStudentData();
          this.dataService.update_storage(false);
        }
      })
    );

    this.subscription$.push(
      this.userDataService.doerCampusBranchesFetchStatus$.subscribe(
        (response: any) => {
          if (response) {
            if (response.status === 'ON_INIT') {
              this.userDataService.updateStudentInformationInStorage();
            }
          }
        }
      )
    )

    this.subscription$.push(
      this.router.events.subscribe(
        (response: any) => {
          if (response instanceof NavigationEnd) {
            this.routesLastChangedAt = Date.now();
          }
        }
      )
    )
  
    this.subscription$.push(
      this.branchProductService.availableProductsList$.subscribe(
        (response: any) => {
          if (response && response.length) {
            this.productAvailablesList = response;
            // if (response.status === 'ON_INIT') {
            //   this.userDataService.updateStudentInformationInStorage();
            // }
          }
        }
      )
    )

    this.subscription$.push(
      this.branchProductService.currentLiveCourseProduct$.subscribe(
        (response: any) => {
          if (response && response.product_setting && response.product_setting.product_route_path) {
            this.currentActiveProductDefaultPath = response.product_setting.product_default_path;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.subscription$.length > 0) {
      for (let subscription of this.subscription$) {
        if (subscription) subscription.unsubscribe();
      }
    }
  }

}
