import { CalendarView } from "angular-calendar";
import { CHANNEL_MEMBER_ROLE_EXCEPT_LEADER_OPTIONS } from "./constant";

export const checkRightLeftScrollPosition = (scrollWidth: number, viewportWidth: number, scrollLeft: number) => {
    let scrolllPosition = { rightEnd: false, leftEnd: false };
    if (Math.ceil(scrollWidth - viewportWidth) === 0) {
        scrolllPosition = { rightEnd: false, leftEnd: false };
    }
    else if (Math.ceil(scrollWidth - viewportWidth) === Math.ceil(scrollLeft)) {
        scrolllPosition = { rightEnd: false, leftEnd: true };
    }
    else if (scrollLeft <= 0) {
        scrolllPosition = { rightEnd: true, leftEnd: false };
    }
    else {
        scrolllPosition = { rightEnd: true, leftEnd: true };
    }
    return scrolllPosition;
};

export const checkStudentsSelectionEquality = (initialSelection: any[], finalSelection: any[]) => {
    const isSame = (initialSelection.length === finalSelection.length) && initialSelection.every((eachSelection) => finalSelection.findIndex((eachFinalSelection) => eachFinalSelection.id == eachSelection.id && eachFinalSelection.type === eachSelection.type) >= 0);
    return isSame;
}

export const checkSimpleArrayEquality = (initialSelection: any[], finalSelection: any[]) => {
    const isSame = (Array.isArray(initialSelection) && Array.isArray(finalSelection)) && (initialSelection.length === finalSelection.length) && initialSelection.every((eachSelection) => finalSelection.findIndex((eachFinalSelection) => eachFinalSelection == eachSelection) >= 0);
    return isSame;
}

export const getFirstDateOfWeek = (date: Date) => {
    const _currentDate = new Date(date);
    const _firstDay = new Date(_currentDate.setDate(_currentDate.getDate() - _currentDate.getDay())); /// FIRST DATE OF SELECTED MONTH
    return _firstDay;
}

export const getLastDateOfWeek = (date: Date) => {
    const _currentDate = new Date(date);
    const _lastDay = new Date(_currentDate.setDate(_currentDate.getDate() + (6 - _currentDate.getDay()))); /// FIRST DATE OF SELECTED MONTH
    return _lastDay;
}

export const getFirstDateOfMonth = (date: Date) => {
    const _currentDate = new Date(date);
    const _firstDay = new Date(_currentDate.setDate(1)); /// FIRST DATE OF SELECTED MONTH
    return _firstDay;
}

export const getLastDateOfMonth = (date: Date) => {
    const _currentDate = new Date(date);
    const _lastDay = new Date(_currentDate.getFullYear(), _currentDate.getMonth() + 1, 0); /// LAST DATE OF SELECTED MONTH
    return _lastDay;
}

export const getFirstDateOfMonthWithSomeDayBefore = (date: Date, dayBefore = 1) => {
    const _currentDate = new Date(date);
    const _firstDay = new Date(_currentDate.setDate(dayBefore)); /// FIRST DATE OF SELECTED MONTH SOME BEFORE DATS
    return _firstDay;
}

export const getLastDateOfMonthWithSomeDayAfter = (date: Date, dayAfter = 0) => {
    const _currentDate = new Date(date);
    const _lastDay = new Date(_currentDate.getFullYear(), _currentDate.getMonth() + 1, dayAfter); /// LAST DATE OF SELECTED MONTH WITH SOME FUTURE DAYS
    return _lastDay;
}

export const getCalendarStartDateBasedOnCalendarViewMode = (date: Date, _current_view: CalendarView) => {
    let _start_date = date;
    if (_current_view === CalendarView.Month) {
        _start_date = getFirstDateOfMonthWithSomeDayBefore(date, -7);
    }
    else if (_current_view === CalendarView.Week) {
        _start_date = getFirstDateOfWeek(date);
    }
    return _start_date;
}

export const getCalendarEndDateBasedOnCalendarViewMode = (date: Date, _current_view: CalendarView) => {
    let _end_date = date;
    if (_current_view === CalendarView.Month) {
        _end_date = getLastDateOfMonthWithSomeDayAfter(date, 7);
    }
    else if (_current_view === CalendarView.Week) {
        _end_date = getLastDateOfWeek(date);
    }
    return _end_date;
}

export const formatCourseBatchLevelDataToCourseLevel = (batchLevelInfo: any) => {
    let { course_id, ...batchInfo } = batchLevelInfo;
    let courseObj: any = {};
    courseObj = Object.assign(courseObj, course_id );
    courseObj.teachers = batchInfo.teachers.concat(batchInfo.support_teachers);
    delete batchInfo.course_id;
    delete batchInfo.teachers;
    delete batchInfo.support_teachers;
    courseObj.price_details = batchInfo;
    return courseObj;
}

export const checkIsNumber = (textValue: any) => {
    if (isNaN(textValue)) {
        return false;
    }
    return true;
}

export const formatGradeSubjectDetail = (_subject_detail: any) => {
    _subject_detail.type = 'SUBJECT';
    _subject_detail.parent_subject_id = null;
    _subject_detail.parent_subject_category_id = null;
    if (_subject_detail.option_subject) {
      _subject_detail.parent_subject_id = _subject_detail.option_subject.subject_id;
      if (_subject_detail.option_subject.category_details) {
        _subject_detail.parent_subject_category_id = _subject_detail.option_subject.category_details.category_id;
      }
    }
    return _subject_detail;
}

export const generateUniqueId = () => {
    return (Math.random().toString(36).substring(2, 11));
}

export const getUniqueFromArrayOfObj = (listData: any[]) => {
    return listData.filter((v, i, a) =>{ return a.findIndex(x => x.id == v.id) === i});
}

export const getUniqueFromArray1D = (listData: any[]) => {
    return Array.from(new Set(listData));
}

export const getMentionedIdsFromEditorContent = (_editor_text: string) => {
    const dyna_elem = document.createElement('div');
    dyna_elem.innerHTML = _editor_text;
    const mentioned_ids = [];
    dyna_elem.querySelectorAll('[data-mention]').forEach((elem: any) => {
        if (elem.dataset.id) {
            mentioned_ids.push(+elem.dataset.id);
        }
    })
    dyna_elem.remove();
    return getUniqueFromArray1D(mentioned_ids);
}

export const getChannelMemberRoleDetail = (_my_member_role: string) => {
    const _member_current_role = CHANNEL_MEMBER_ROLE_EXCEPT_LEADER_OPTIONS.find((_member_role) => _member_role.id === _my_member_role);
    if (_member_current_role) {
        return _member_current_role;
    }
    return null;
}