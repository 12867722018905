import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@studentapp_core/services/APIService/auth.service';
import { LocalService } from '@studentapp_core/services/local.service';
import { UserDataService } from '@studentapp_core/services/user-data.service';
import { environment } from '@studentapp_env/environment';
import { WarningDeleteItemComponent } from '@studentapp_shared/common-dialog/warning-delete-item/warning-delete-item.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-selection-dialog',
  templateUrl: './account-selection-dialog.component.html',
  styleUrls: ['./account-selection-dialog.component.scss']
})
export class AccountSelectionDialogComponent implements OnInit, OnDestroy {

  env = environment;
  imagePath = this.env.cdn_urls.image;

  dataLoading = true;
  linkedAccountsList: any[] = [];
  // isDisabled: Boolean = false;

  currentUserData: any = null;
  currentStudentData: any = null;
  selectedAccountCtrl = new FormControl();

  warningDialogRef: MatDialogRef<WarningDeleteItemComponent>;
  apiSubscription: Subscription = null;
  subscriptions: Subscription[] = [];

  constructor(
    private localService: LocalService,
    private userDataService: UserDataService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<AccountSelectionDialogComponent>,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.currentUserData = this.localService.getCurrentUserData();
    this.currentStudentData = this.localService.getCurrentStudentData();
    this.selectedAccountCtrl.setValue(this.currentUserData.id);
  }

  // addNewStudent() {
  //   this.dialogRef.close();
  //   this.router.navigate(['', { outlets: { modal: 'student/addStudent' }}]);
  // }

  // /* get seletec student's data and pass it no another compoent */
  // getSeletedStudent(index) {
  //   this.isDisabled = true;
  //   setTimeout(() => {
  //     this.dialogRef.close(this.students_data[index]);
  //     this.isDisabled = false;
  //   }, 1000);
  // }

  tryToSwitchAutomaticallyInOtherAccount(_account_detail: any) {
    // if targeted account is parent account then no need to check for password, always ask for password
    if (_account_detail.process.is_parent) {
      this.selectedAccountCtrl.setValue(_account_detail.id);
      this.dialogRef.close({ status: 'success', action: 'logout_from_current_manual_login', login_detail: { username: _account_detail.username, email: _account_detail.email } });
      return;
    }
    // else check for switch account feature
    const _payload_body = {
      username: _account_detail.username,
    };
    this.apiSubscription = this.userDataService.switchToAnotherAccount(_payload_body).subscribe(
      (response: any) => {
        this.selectedAccountCtrl.setValue(_account_detail.id);
        this.dialogRef.close({ status: 'success', action: 'logout_from_current_auto_new_login', login_detail: response })
      },
      (error: any) => {
        this.selectedAccountCtrl.setValue(_account_detail.id);
        this.dialogRef.close({ status: 'success', action: 'logout_from_current_manual_login', login_detail: { username: _account_detail.username, email: _account_detail.email } })
      }
    )
  }

  showWarningDialogBeforeSwitchDifferentAccount(_account_detail: any) {
    const _account_name = _account_detail.process.students[0].nick_name ? _account_detail.process.students[0].nick_name : _account_detail.process.students[0].name ? _account_detail.process.students[0].name : _account_detail.username;
    this.warningDialogRef = this.dialog.open(
      WarningDeleteItemComponent,
      {
        height: 'auto',
        width: '600px',
        data: {
          index: -1,
          modalInfo: { accountInfo: _account_detail },
          displayMsg: `Are you sure want to login into ${_account_name}'s account. By confirming this you will be logout from current account, also you may need to give password for this account?`,
        },
        panelClass: 'warning-msg-modal'
      }
    );

    this.subscriptions.push(
      this.warningDialogRef.afterClosed().subscribe(
        (response: any) => {
          if (response && response.actionMode === 'DELETE') {
            this.tryToSwitchAutomaticallyInOtherAccount(_account_detail);
          }
        }
      )
    )
  }

  getParentDetails() {
    this.apiSubscription = this.userDataService.getLinkedAccountUsersList().subscribe(
      (response: any) => {
        this.linkedAccountsList = response;
        this.dataLoading = false;
      },
      (error: any) => {
        this.dataLoading = false;
        console.error(error)
      }
    )
  }

  ngOnInit() {
    this.getParentDetails();
  }

  ngOnDestroy(): void {
    this.apiSubscription?.unsubscribe();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
