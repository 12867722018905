import { version } from './version';

export const environment = {
  production: false,
  version: version,
  configMode: 'LOCAL_SERVE',
  // base_url: 'https://api2.doerdo.com/api',
  base_url: 'https://a3.clinilead.com/api',
  // base_url: 'https://v1.clinilead.com/api',
  // base_url: 'http://165.173.0.183:801/api',
  segmentAnalytics: {
    writeKey: 'NkAhaU5zFb155s06AxMjY3nhg4v3fnTy',
  },
  cdn_urls: {
    image: 'https://content.doerdo.com/',
    video: 'https://video.doerdo.com/',
    audio: 'https://video.doerdo.com/audio-media/',
    white_board_content: 'https://wb.doerdo.com/', 
    recording: 'https://recording.doerdo.com/',
    class_asset: 'https://wb.doerdo.com/class_assets/',
    chat_attachment_files: 'https://dd2-files.s3.ap-southeast-1.amazonaws.com/message_attachment/',
  },
  socket: {
    base_url: 'https://s2.clinilead.com',
    base_path: '/socket.io/',
  },
  // AWS: {
  //   BUCKET: 'cliniled',
  //   ACCESS_KEY: 'AKIARX4OHJSBGLMOBRBF',
  //   SECRET_ACCESS_KEY: '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',
  //   UPLOAD_PATH: 'https://cliniled.s3.amazonaws.com/',
  //   IMAGE_PATH: 'upload/',
  //   VIDEO_PATH: 'videos/',
  //   CLASS_ASSETS_PATH: 'class_assets/',
  //   CLASS_RECORDING_DOMAIN: 'https://doerdo-classes.s3.ap-southeast-1.amazonaws.com/',
  //   CLASS_RECORDING_PATH: 'classrecording/',
  // },
  productsConfig: {
    doerDo: {
      link_branch_ids: [34],
    },
    doerAeiser: {
      link_branch_ids: [110],
    },
    doerCode: {
      link_branch_ids: [101],
    },
    doerSpark: {
      link_branch_ids: [100],
    },
    doerMath: {
      link_branch_ids: [105],
    },
    doerTalk: {
      link_branch_ids: [69],
    },
    doerCampus: {
      link_branch_ids: [],
    }
  },
  sentry_dsn: 'https://1a7d1e0cb316417ab8c1a1fd1ba32321@o383278.ingest.sentry.io/5275306',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
