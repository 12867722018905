import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../core/core.module';
import { LeftSidebarComponent } from './left-sidebar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { TopNavbarDefaultModule } from '@studentapp_pages/layout/top-navbar-default/top-navbar-default.module';
import { DoerschoolBranchSelectionDialogModule } from '@studentapp_pages/dialog-box/doerschool-branch-selection-dialog/doerschool-branch-selection-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { RouteActiveDetectModule } from '@studentapp_core/pipes/route-active-detect/route-active-detect.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CoreModule,
        MatTooltipModule,
        MatMenuModule,
        MatDialogModule,
        TopNavbarDefaultModule,
        RouteActiveDetectModule,
        DoerschoolBranchSelectionDialogModule,
    ],
    exports: [LeftSidebarComponent],
    declarations: [LeftSidebarComponent],
    providers: [],
})
export class LeftSidebarModule { }
