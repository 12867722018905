<!-- <span class="no-data-msg" [ngClass]="panelClass">
    <img [src]="boxType === 'INFO' ? 'assets/images/svg/info.svg' : ''" alt=""/> 
    {{ NoDataText }}
    <button type="button" class="btn" [ngClass]="actionBtnClass" *ngIf="actionAllowed" (click)="triggerActionReq()">{{ actionText }}</button>
</span> -->

<span class="no-data-msg" [ngClass]="panelClass">
    <!-- with-btn -->
    <img *ngIf="visibleIcon" [src]="iconPath" alt=""/> 
    <ng-container *ngIf="useTextFormatAs === 'NORMAL'">{{ NoDataText }}</ng-container>
    <ng-container *ngIf="useTextFormatAs === 'HTML'">
        <span [innerHTML]="NoDataText | safeHtml"></span>
    </ng-container>
    <button type="button" class="btn" [ngClass]="actionBtnClass" *ngIf="actionAllowed" (click)="triggerActionReq()">{{ actionText }}</button>
</span>