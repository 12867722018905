import { LocalService } from "@studentapp_core/services/local.service";
import { UserDataService } from "@studentapp_core/services/user-data.service";

export function userDetailInitializer(
    localService: LocalService,
    userDataService: UserDataService,
): () => Promise<any> {
    return () => new Promise(async (resolve, reject) => {
        const currentUser = localService.getCurrentUser();
        const currentStudentId = localService.getCurrentStudentId();
        const _school_branch_info = localService.getCurrentDoerCampusBranchInfo();
        if (_school_branch_info) {
            userDataService.setDoerCampusBranchIdsInEnvironment(_school_branch_info.id);
        }
        let _latest_user_info = null;
        if (currentUser && currentStudentId && !_school_branch_info) {
            try {
                _latest_user_info = await userDataService.getUserDetails(currentUser.user.id).toPromise();
                if (_latest_user_info) {
                    userDataService.updateCurrentUserAndStudentDetails(_latest_user_info.accountInfo);
                }
                userDataService.updateDoerCampusBranchesFetchStatusInfo({ status: 'USER_DATA_FETCHED' });
            } catch (error) {
                
            }
        }
        resolve(true);
    })
}