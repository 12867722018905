import { PRODUCT_SETTING_DATA, PRODUCT_VISIBILITY_DEFAULT_ORDER_DATA } from "@studentapp_core/common/constant";
import { BranchProductService } from "@studentapp_core/services/branch-product.service";
// import { LocalService } from "@studentapp_core/services/local.service";
// import { UserDataService } from "@studentapp_core/services/user-data.service";

export function availableProductsInitializer(
    // localService: LocalService,
    branchProductService: BranchProductService,
    // userDataService: UserDataService,
): () => Promise<any> {
    return () => new Promise(async (resolve, reject) => {
        const _current_products_list = branchProductService.availableProductsList.value;
        if (_current_products_list.length === 0) {
            const _products_available_list = JSON.parse(JSON.stringify(PRODUCT_VISIBILITY_DEFAULT_ORDER_DATA));
            _products_available_list.forEach((_each_product) => {
              _each_product.product_setting = PRODUCT_SETTING_DATA[_each_product.product_code];
            })
            branchProductService.updateAvailableProductsList(_products_available_list);
        }
        resolve(true);
    })
}